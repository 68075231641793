
import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import CtaButton from '../components/CtaButton'



const Pricing = () => {
  return (
    <Layout>


    <Helmet>
        <title>Pricing | InHero</title>
        <meta name="description" content="Get started with a 30 day free trial." />
        <meta property="og:title" content="Pricing | InHero"/>
    </Helmet>

    <section className="pricing_section">
      <h1>Start growing, risk free</h1>
     
    </section>


    <section>
  
    <div class="pricing_table">
      <div class="pricegrid__container">
     
        <div class="pricegrid__row">
          <div class="pricegrid__cell pricegrid__headercolumn"></div>
          <div class="pricegrid__cell pricegrid__headerrow">
            <div class="hide pricegrid__mostpopular"></div>
            <div class="pricegrid__title">LITE</div>

            <div className="pricegrid_group">
              <div class="pricegrid__price">£45</div>
              <div class="pricegrid__price__permonth">/monthly</div>
            </div>
            
            <CtaButton classes={"pricingmaincta"}/>
          </div>
          <div class="pricegrid__cell pricegrid__cell__mostpopular pricegrid__headerrow">
            <div class="pricegrid__mostpopular"><div>MOST POPULAR</div></div>
            <div class="pricegrid__title">STANDARD</div>

            <div className="pricegrid_group">
              <div class="pricegrid__price">£70</div>
              <div class="pricegrid__price__permonth">/monthly</div>
            </div>
        
        

            <CtaButton classes={"pricingmaincta"}/>

          
          </div>

         



          
        </div>
        <div class="pricegrid__row">
          <div class="pricegrid__cell pricegrid__headercolumn">
            <div>Get Google & <br/>Facebook reviews</div>
          </div>
          <div class="pricegrid__cell"><div className="pricing-tick"></div></div>
          <div class="pricegrid__cell"><div className="pricing-tick"></div></div>


        </div>
        <div class="pricegrid__row">
          <div class="pricegrid__cell pricegrid__headercolumn">
            <div>Get reviews on 150+ other sites</div>
          </div>

          <div class="pricegrid__cell"><div className="pricing-tick"></div></div>
          <div class="pricegrid__cell"><div className="pricing-tick"></div></div>
        </div>


        <div class="pricegrid__row">
          <div class="pricegrid__cell pricegrid__headercolumn">
            <div>Send review invites <br/> via SMS or Email</div>
          </div>
          <div class="pricegrid__cell"><div className="pricing-tick"></div></div>
          <div class="pricegrid__cell"><div className="pricing-tick"></div></div>
        </div>



        <div class="pricegrid__row">
          <div class="pricegrid__cell pricegrid__headercolumn">
            <div>Monthly review invites</div>
          </div>
          <div class="pricegrid__cell"><div>200</div></div>
          <div class="pricegrid__cell"><div>500</div></div>
         
        </div>
        <div class="pricegrid__row">
          <div class="pricegrid__cell pricegrid__headercolumn">
            <div>Get <span className="span-bold">10x more</span> reviews</div>
          </div>
          <div class="pricegrid__cell">
            <div className="pricing-tick"></div>
          </div>
          <div class="pricegrid__cell">
            <div className="pricing-tick"></div>
          </div>
        </div>
        <div class="pricegrid__row">
          <div class="pricegrid__cell pricegrid__headercolumn">
            <div>Smart review funnel</div>
          </div>
          <div class="pricegrid__cell"><div className="pricing-tick"></div></div>
          <div class="pricegrid__cell"><div className="pricing-tick"></div></div>




        </div>
        <div class="pricegrid__row">
          <div class="pricegrid__cell pricegrid__headercolumn">
            <div>Quick support</div>
          </div>
          <div class="pricegrid__cell">
          <div className="pricing-tick"></div>
          </div>
          <div class="pricegrid__cell">
          <div className="pricing-tick"></div>
          </div>
        </div>
        <div class="pricegrid__row">
          <div class="pricegrid__cell pricegrid__headercolumn">
            <div>No contract,<br/>leave anytime</div>
          </div>
          <div class="pricegrid__cell">
          <div className="pricing-tick"></div>
          </div>
          <div class="pricegrid__cell">
          <div className="pricing-tick"></div>
          </div>
        </div>
        

      </div>
    </div>
   
  </section>

  <div className="container">

<section className="pricing-section-bottom">

    <p>Get started with a 30 day free trial of InHero, no card required</p>

         <CtaButton/>
      


</section>
</div>

  


 












    </Layout>
  )
}

export default Pricing